.App {
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 300px;
  border: 1px;
  border-style: solid;
}

.row {
  display: flex;
  justify-content: center;
  text-align: center;
}

.cell {
  width: 75px;
  height: 75px;
  /* border: 1px; */
  font-size: 25px;
  /* margin: -0.5px; */
  /* border-style: solid; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #3b444c;
}
